import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import DesignTokens from "./designTokens"
import { Container, Row, Col } from "react-grid-system"

const CategoryBreadcrumbs = ({ category }) => (
  <Wrapper>
    <Container>
      <Row>
        <Col>
          <ul>
            {/* <li>
              <Link to="/">Home</Link>
            </li> */}
            {category.treeParent && category.treeParent.treeParent && (
              <li>
                <Link to={`/${category.treeParent.treeParent.slug}/`}>
                  {category.treeParent.treeParent.name}
                </Link>
              </li>
            )}
            {category.treeParent && (
              <li>
                <Link to="../">{category.treeParent.name}</Link>
              </li>
            )}
            <li>{category.name}</li>
          </ul>
        </Col>
      </Row>
    </Container>
  </Wrapper>
)
const Wrapper = styled.div`
  padding-top: 3rem;
  padding-bottom: 3rem;
  @media screen and (max-width: 767px) {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  ul {
    display: flex;
    flex-direction: row;
    margin: 0;
    list-style: none;
    justify-content: center;
    align-content: center;
    & > * {
      font-size: 14px;
      margin-right: 0.75rem;
      &::after {
        content: "›";
        margin-left: 0.75rem;
        @media screen and (max-width: 767px) {
          display: none;
        }
      }
      &:last-child {
        margin-right: 0;
        &::after {
          display: none;
        }
      }
    }
    @media screen and (max-width: 767px) {
      flex-direction: column;
      text-align: center;
    }
  }
`

export default CategoryBreadcrumbs
