import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import DesignTokens from "./designTokens"
import { Lead } from "./typography"

const CategoryNav = ({ categories, root }) => {
  categories.map(category => {
    category.treeChildren.sort((a, b) => a.position - b.position)
    category.treeChildren.map(category => {
      if (category.treeChildren.length > 0) {
        category.treeChildren.sort((a, b) => a.position - b.position)
      }
    })
  })
  return (
    <Wrapper>
      <ul>
        {categories.map(category => (
          <>
            <li key={category.originalId}>
              <Link
                to={`/${category.slug}/`}
                activeClassName="selected"
                partiallyActive={true}
              >
                <Lead>{category.name}</Lead>
              </Link>
              {!root && (
                <ul>
                  {category.treeChildren.map(subCategory => (
                    <>
                      <li key={subCategory.originalId}>
                        <Link
                          to={`/${category.slug}/${subCategory.slug}/`}
                          activeClassName="selected"
                          partiallyActive={true}
                        >
                          {subCategory.name}
                        </Link>
                        {subCategory.treeChildren.length > 0 && (
                          <ul>
                            {subCategory.treeChildren.map(subSubCategory => (
                              <li key={subSubCategory.originalId}>
                                <Link
                                  to={`/${category.slug}/${subCategory.slug}/${subSubCategory.slug}/`}
                                  activeClassName="selected"
                                  partiallyActive={true}
                                >
                                  {subSubCategory.name}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        )}
                      </li>
                    </>
                  ))}
                </ul>
              )}
            </li>
          </>
        ))}
      </ul>
    </Wrapper>
  )
}
const Wrapper = styled.div`
  ul {
    list-style: none;
    li > ul {
      display: none;
    }
    @media screen and (max-width: 767px) {
      margin: 0;
    }
  }
  a {
    color: ${DesignTokens.colors.secondary[500]};
    text-decoration: none;
    &.selected,
    &:hover {
      color: ${DesignTokens.colors.primary[500]};
      h5 {
        color: ${DesignTokens.colors.primary[500]};
      }
    }
    &.selected {
      font-weight: 600;
      & + ul {
        display: block;
      }
    }
  }
`

export default CategoryNav
