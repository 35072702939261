import React from "react"
import styled from "styled-components"
import DesignTokens from "./designTokens"
import { Link } from "gatsby"
import Img from "gatsby-image"
import { Caption, HeadingSmall } from "./typography"
import placeholder from "../images/product-placeholder.jpg"

const ProductThumb = ({ product }) => (
  <Wrapper>
    <Link to={`/prodotti/${product.slug}`}>
      {product.items[0].image ? (
        <Img fluid={product.items[0].image.fluid} />
      ) : (
        <img src={placeholder} />
      )}
      <Details>
        <Caption>{product.categories[0].name}</Caption>
        <HeadingSmall>{product.name}</HeadingSmall>
      </Details>
    </Link>
  </Wrapper>
)
const Wrapper = styled.div`
  border: 1px solid ${DesignTokens.colors.secondary[100]};
  a {
    text-decoration: none;
  }
  min-height: 100%;
`

const Details = styled.div`
  padding: 2rem;
  text-align: center;
  & > * {
    margin-bottom: 1rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
`

export default ProductThumb
