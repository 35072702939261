import React from "react"
import { graphql } from "gatsby"
import { Layout } from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"
import { useCategories } from "../hooks/useCategories"
import GetProductsByCategoryId from "../utils/getProductByCategoryId"
import { Col, Container, Row, Visible } from "react-grid-system"
import ProductThumb from "../components/productThumb"
import CategoryBreadcrumbs from "../components/categoryBredcrumbs"
import CategoryNav from "../components/categoryNav"
import CategoryThumb from "../components/categoryThumb"

const CategoryPage = ({ data: { category }, pageContext }) => {
  // console.log(useProducts(), category.originalId)
  const categories = useCategories()
  let categoryProducts = GetProductsByCategoryId(category.originalId)
  if (category.root) {
    category.treeChildren.sort((a, b) => a.position - b.position)
  }
  return (
    <Layout>
      <SEO title={category.name} />
      <Wrapper>
        <CategoryBreadcrumbs category={category} />
        <Container>
          <Row>
            <Visible lg xl xxl>
              <Col md={3} sm={12}>
                <CategoryNav categories={categories} root={category.root} />
              </Col>
            </Visible>
            <Col md={12} lg={9} sm={12}>
              <Row gutterWidth={0}>
                {category.root ? (
                  <>
                    {category.treeChildren.map(category => (
                      <Col xs={12} sm={6} lg={4} xxl={3} key={category.id}>
                        <CategoryThumb category={category} />
                      </Col>
                    ))}
                  </>
                ) : (
                  <>
                    {categoryProducts.map(product => (
                      <Col xs={12} sm={6} lg={4} xxl={3} key={product.id}>
                        <ProductThumb product={product} />
                      </Col>
                    ))}
                  </>
                )}
              </Row>
            </Col>
            <Visible xs md sm>
              <Col lg={3} sm={12}>
                <CategoryNav categories={categories} root={category.root} />
              </Col>
            </Visible>
          </Row>
        </Container>
      </Wrapper>
    </Layout>
  )
}

export default CategoryPage

const Wrapper = styled.div``

export const query = graphql`
  query CategoryQuery($originalId: String!) {
    category: datoCmsCategory(originalId: { eq: $originalId }) {
      name
      slug
      originalId
      root
      treeParent {
        originalId
        name
        slug
        treeParent {
          name
          slug
          originalId
        }
      }
      treeChildren {
        originalId
        name
        slug
        position
        image {
          fluid(maxWidth: 768, imgixParams: { fm: "jpg" }) {
            ...GatsbyDatoCmsFluid
          }
        }
      }
    }
  }
`
